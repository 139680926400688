import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import Login from "./Login";
import Home from "./Home";
import StockChecker from "./StockChecker";
import Resources from "./Resources";
import Stocks101 from "./modules/Stocks101";
import BullMarketGlossary from "./glossary/BullMarket";
import BearMarketGlossary from "./glossary/BearMarket";
import CompoundInterestCalculator from "./tools/CompoundInterestCalculator";
import DiversificationSimulator from "./tools/DiversificationSimulator";
import netlifyIdentity from "netlify-identity-widget";
import "./App.css";

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    netlifyIdentity.init();
    const user = netlifyIdentity.currentUser();
    if (user) {
      setUser(user);
    }
  }, []);

  const handleLogin = (user) => {
    setUser(user);
  };

  const handleLogout = () => {
    netlifyIdentity.logout();
    setUser(null);
  };

  return (
    <Router>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            StockSnap
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/resources">
                Resources
              </Nav.Link>
            </Nav>
            <Nav>
              {user ? (
                <>
                  <Nav.Item className="me-2">
                    Welcome, {user.user_metadata.full_name}
                  </Nav.Item>
                  <Nav.Link as={Link} to="/app">
                    App
                  </Nav.Link>
                  <Login onLogin={handleLogout} />
                </>
              ) : (
                <Login onLogin={handleLogin} />
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-5">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/app" element={<StockChecker user={user} />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/modules/stocks" element={<Stocks101 />} />
          <Route path="/glossary/bullmarket" element={<BullMarketGlossary />} />
          <Route path="/glossary/bearmarket" element={<BearMarketGlossary />} />
          <Route
            path="/tools/compoundinterestcalculator"
            element={<CompoundInterestCalculator />}
          />
          <Route
            path="/tools/diversificationsimulator"
            element={<DiversificationSimulator />}
          />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
