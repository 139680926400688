import React, { useEffect } from "react";
import netlifyIdentity from "netlify-identity-widget";
import { Button } from "react-bootstrap";

const Login = ({ onLogin }) => {
  useEffect(() => {
    netlifyIdentity.init();
    netlifyIdentity.on("login", (user) => {
      onLogin(user); // Call onLogin function to pass user to parent component
      netlifyIdentity.close();
    });
    netlifyIdentity.on("logout", () => {
      onLogin(null); // Call onLogin function with null to clear user state
    });

    return () => {
      netlifyIdentity.off("login");
      netlifyIdentity.off("logout");
    };
  }, [onLogin]);

  return (
    <div>
      {netlifyIdentity.currentUser() ? (
        <Button onClick={() => netlifyIdentity.logout()}>Logout</Button>
      ) : (
        <Button onClick={() => netlifyIdentity.open()}>Login</Button>
      )}
    </div>
  );
};

export default Login;
