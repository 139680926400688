import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faVideo,
  faListAlt,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./App.css";

const modules = [
  {
    title: "Stocks 101",
    link: "/modules/stocks",
    description: "Learn the basics of stocks and investing.",
    icon: faBook,
  },
  {
    title: "Technical Analysis",
    link: "/modules/technical-analysis",
    description: "Understand technical analysis and chart patterns.",
    icon: faBook,
  },
  {
    title: "Portfolio Management",
    link: "/modules/portfolio-management",
    description: "Manage your portfolio effectively.",
    icon: faBook,
  },
];

const videos = [
  {
    title: "Introduction to Investing",
    youtubeId: "iCzBVWdNOeE",
    description: "A beginner's guide to investing.",
    icon: faVideo,
  },
  {
    title: "Understanding Market Trends",
    youtubeId: "21b5QF-b0rE",
    description: "Insights on how to read market trends.",
    icon: faVideo,
  },
];

const glossary = [
  { term: "Bull Market", link: "/glossary/bullmarket", icon: faListAlt },
  { term: "Bear Market", link: "/glossary/bearmarket", icon: faListAlt },
];

const tools = [
  {
    name: "Compound Interest Calculator",
    link: "/tools/compoundinterestcalculator",
    icon: faTools,
  },
  {
    name: "Diversification Simulator",
    link: "/tools/diversificationsimulator",
    icon: faTools,
  },
];

const EducationalResources = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState("");

  const handleShowModal = (youtubeId) => {
    setSelectedVideoId(youtubeId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const ModalComponent = () => {
    const [iframeDimensions, setIframeDimensions] = useState({
      width: "100%",
      height: "100%",
    });

    useEffect(() => {
      const handleResize = () => {
        const maxWidthPercentage = 0.6;
        const maxHeightPercentage = 0.6;
        const aspectRatio = 16 / 9;

        // Calculate dimensions based on viewport size and aspect ratio
        const maxWidth = window.innerWidth * maxWidthPercentage;
        const maxHeight = window.innerHeight * maxHeightPercentage;

        let width = maxWidth;
        let height = maxHeight;

        if (height * aspectRatio > width) {
          height = width / aspectRatio;
        } else {
          width = height * aspectRatio;
        }

        setIframeDimensions({
          width: `${width}px`,
          height: `${height}px`,
        });
      };

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        contentClassName="videoModal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              style={{
                width: iframeDimensions.width,
                height: iframeDimensions.height,
                border: "none",
              }}
              title="YouTube Video"
              className="embed-responsive-item"
              src={`https://www.youtube.com/embed/${selectedVideoId}`}
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10}>
          <div className="text-center">
            <h1 className="mb-4 display-4">Educational Resources</h1>
            <p className="lead">
              Enhance your investment knowledge with our comprehensive
              resources.
            </p>
          </div>

          <hr className="my-5" />

          <div className="text-center">
            <h2 className="mb-4">Learning Modules</h2>
            <Row className="mb-5">
              {modules.map((module, index) => (
                <Col md={4} className="mb-3" key={index}>
                  <Card className="h-100 border-0 shadow-sm">
                    <Card.Body>
                      <FontAwesomeIcon
                        icon={module.icon}
                        size="3x"
                        className="mb-3 text-primary"
                      />
                      <Card.Title className="h5">{module.title}</Card.Title>
                      <Card.Text className="text-muted">
                        {module.description}
                      </Card.Text>
                      <Button
                        variant="outline-primary"
                        as={Link}
                        to={module.link}
                        className="mb-3"
                      >
                        Learn More
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <hr className="my-5" />

          <div className="text-center">
            <h2 className="mb-4">Videos and Webinars</h2>
            <Row className="mb-5">
              {videos.map((video, index) => (
                <Col md={6} className="mb-3" key={index}>
                  <Card className="h-100 border-0 shadow-sm">
                    <Card.Body>
                      <FontAwesomeIcon
                        icon={video.icon}
                        size="3x"
                        className="mb-3 text-primary"
                      />
                      <Card.Title className="h5">{video.title}</Card.Title>
                      <Card.Text className="text-muted">
                        {video.description}
                      </Card.Text>
                      <Button
                        variant="outline-primary"
                        onClick={() => handleShowModal(video.youtubeId)}
                        className="mb-3"
                      >
                        Watch Now
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <hr className="my-5" />

          <div className="text-center">
            <h2 className="mb-4">Glossary</h2>
            <Row className="mb-5">
              {glossary.map((item, index) => (
                <Col md={6} className="mb-3" key={index}>
                  <Card className="h-100 border-0 shadow-sm">
                    <Card.Body>
                      <FontAwesomeIcon
                        icon={item.icon}
                        size="3x"
                        className="mb-3 text-primary"
                      />
                      <Card.Title className="h5">{item.term}</Card.Title>
                      <Button
                        variant="outline-primary"
                        as={Link}
                        to={item.link}
                        className="mb-3"
                      >
                        Learn More
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <hr className="my-5" />

          <div className="text-center">
            <h2 className="mb-4">Interactive Tools</h2>
            <Row className="mb-5">
              {tools.map((tool, index) => (
                <Col md={6} className="mb-3" key={index}>
                  <Card className="h-100 border-0 shadow-sm">
                    <Card.Body>
                      <FontAwesomeIcon
                        icon={tool.icon}
                        size="3x"
                        className="mb-3 text-primary"
                      />
                      <Card.Title className="h5">{tool.name}</Card.Title>
                      <Button
                        variant="outline-primary"
                        as={Link}
                        to={tool.link}
                        className="mb-3"
                      >
                        Use Tool
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <ModalComponent />
        </Col>
      </Row>
    </Container>
  );
};

export default EducationalResources;
