import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Form,
  FormControl,
  Button,
  Spinner,
  Card,
  Row,
  Col,
  Carousel,
} from "react-bootstrap";
import { Routes, Route, Navigate } from "react-router-dom";

const StockChecker = ({ user, handleLogin }) => {
  const [inputValue, setInputValue] = useState("");
  const [ticker, setTicker] = useState("");
  const [stockInfo, setStockInfo] = useState(null);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [favorites, setFavorites] = useState([]);

  const handleInputChange = (e) => setInputValue(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue) {
      setError("Ticker is required");
      return;
    }
    setTicker(inputValue);
    fetchData(inputValue);
  };

  const fetchData = async (ticker) => {
    setLoading(true);
    try {
      const response = await axios.post(
        //LIVE
        "https://aaronr.pythonanywhere.com/get_stock_price",
        //DEV
        //"http://localhost:5000/get_stock_price",
        { ticker }
      );
      setStockInfo(response.data);
      setLastUpdated(new Date().toLocaleString());
      setError(null);
    } catch (err) {
      console.error(err);
      setError("Invalid ticker");
    } finally {
      setLoading(false);
    }
  };

  const addFavorite = () => {
    if (favorites.includes(ticker)) return;
    setFavorites([...favorites, ticker]);
  };

  const removeFavorite = (tickerToRemove) => {
    setFavorites(favorites.filter((ticker) => ticker !== tickerToRemove));
  };

  const getPriceColor = (price) =>
    price >= stockInfo.open ? "text-success" : "text-danger";

  const getDiffColor = (diff) => (diff > 0 ? "text-success" : "text-danger");

  return (
    <Container>
      <Routes>
        <Route
          path="/"
          element={
            user ? (
              <div>
                <h1>Stock Checker</h1>
                <Form
                  onSubmit={handleSubmit}
                  className="d-flex justify-content-center"
                >
                  <FormControl
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter stock ticker"
                    className="me-2"
                    required
                  />
                  <Button variant="primary" type="submit">
                    Get Price
                  </Button>
                </Form>
                {lastUpdated && <p>Last Updated: {lastUpdated}</p>}
                {error && (
                  <div className="mt-4">
                    <h2 style={{ color: "red" }}>Error: {error}</h2>
                  </div>
                )}
                {loading ? (
                  <div className="mt-4 d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  stockInfo &&
                  !error && (
                    <div>
                      <Row>
                        <Col>
                          <div className="mt-4">
                            <Card className="mb-4">
                              <Card.Body>
                                <Card.Title>{stockInfo.name}</Card.Title>
                                <Card.Text>{stockInfo.info}</Card.Text>
                                <Button
                                  href={stockInfo.websiteLink}
                                  target="_blank"
                                  variant="primary"
                                >
                                  Visit {stockInfo.name} Website
                                </Button>
                                <Button
                                  variant="warning"
                                  className="ms-3"
                                  onClick={addFavorite}
                                >
                                  Add to Favorites
                                </Button>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                        <Col>
                          <div className="mt-4">
                            <Card className="border mb-4">
                              <Card.Body>
                                <Card.Title>Recent News</Card.Title>
                                <Carousel
                                  indicators={false}
                                  prevLabel=""
                                  nextLabel=""
                                >
                                  {stockInfo.news.map((article) => (
                                    <Carousel.Item
                                      key={article.uuid}
                                      className="compact-card"
                                    >
                                      <div className="d-flex flex-column flex-md-row">
                                        {article.thumbnail && (
                                          <div className="col-md-4">
                                            <Card.Img
                                              variant="top"
                                              src={
                                                article.thumbnail.resolutions[0]
                                                  .url
                                              }
                                              className="img-fluid rounded-start"
                                              alt={article.title}
                                            />
                                          </div>
                                        )}
                                        <div
                                          className={
                                            article.thumbnail
                                              ? "col-md-8"
                                              : "col-md-12"
                                          }
                                        >
                                          <Card.Body className="d-flex flex-column h-100">
                                            <Card.Title className="h5">
                                              {article.title}
                                            </Card.Title>
                                            <Card.Text className="flex-grow-1">
                                              {article.publisher}
                                            </Card.Text>
                                            <Button
                                              href={article.link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              variant="primary"
                                              className="mt-auto align-self-center"
                                            >
                                              Read more
                                            </Button>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Card className="mb-4">
                          <Card.Body>
                            <Card.Title>Stock Information</Card.Title>
                            <Row className="mt-4">
                              <Col sm={6} md={4} lg={3}>
                                <Card className="info-card">
                                  <Card.Body>
                                    <strong>Current Price:</strong>{" "}
                                    <span
                                      className={getPriceColor(stockInfo.price)}
                                    >
                                      ${stockInfo.price.toFixed(2)}
                                    </span>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col sm={6} md={4} lg={3}>
                                <Card className="info-card">
                                  <Card.Body>
                                    <strong>Opening Price:</strong>{" "}
                                    <span>${stockInfo.open.toFixed(2)}</span>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col sm={6} md={4} lg={3}>
                                <Card className="info-card">
                                  <Card.Body>
                                    <strong>Closing Price:</strong>{" "}
                                    <span>
                                      {stockInfo.close !== undefined
                                        ? `$${stockInfo.close.toFixed(2)}`
                                        : "Not available"}
                                    </span>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col sm={6} md={4} lg={3}>
                                <Card className="info-card">
                                  <Card.Body>
                                    <strong>Daily High:</strong>{" "}
                                    <span>${stockInfo.high.toFixed(2)}</span>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col sm={6} md={4} lg={3}>
                                <Card className="info-card">
                                  <Card.Body>
                                    <strong>Daily Low:</strong>{" "}
                                    <span>${stockInfo.low.toFixed(2)}</span>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col sm={6} md={4} lg={3}>
                                <Card className="info-card">
                                  <Card.Body>
                                    <strong>Volume:</strong>{" "}
                                    <span>
                                      {stockInfo.volume.toLocaleString()}
                                    </span>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col sm={6} md={4} lg={3}>
                                <Card className="info-card">
                                  <Card.Body>
                                    <strong>
                                      Difference (Current - Open):
                                    </strong>{" "}
                                    <span
                                      className={getDiffColor(
                                        stockInfo.price - stockInfo.open
                                      )}
                                    >
                                      $
                                      {(
                                        stockInfo.price - stockInfo.open
                                      ).toFixed(2)}
                                    </span>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Row>
                      <Row>
                        <Col>
                          <h2>Favorites</h2>
                          <ul>
                            {favorites.map((ticker) => (
                              <li key={ticker}>
                                {ticker}{" "}
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => removeFavorite(ticker)}
                                >
                                  Remove
                                </Button>
                              </li>
                            ))}
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  )
                )}
              </div>
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Container>
  );
};

export default StockChecker;
