import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faInfoCircle,
  faListAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const BullMarketGlossary = () => {
  const definition =
    "A bull market is a financial market in which prices are rising or are expected to rise. The term 'bull market' is most often used to refer to the stock market but can be applied to anything that is traded, such as bonds, currencies, and commodities.";
  const characteristics = [
    "Rising stock prices",
    "Increased investor confidence",
    "Strong economic indicators",
    "Higher trading volumes",
    "Prolonged period of price increases",
  ];
  const examples = [
    "The 1990s dot-com bubble",
    "Post-2008 financial crisis recovery",
    "2010s tech boom",
  ];
  const relatedTerms = [
    "Bear Market",
    "Market Trend",
    "Stock Market",
    "Investor Sentiment",
  ];

  return (
    <Container>
      <Row>
        <Col md={12} className="text-md-start">
          <Button
            variant="outline-primary"
            as={Link}
            to="/resources"
            className="mb-3"
          >
            Back to Resources
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <div className="text-center">
            <h1 className="mb-4 display-4">Glossary: Bull Market</h1>
            <p className="lead">
              Understand the concept of a Bull Market and its significance.
            </p>
          </div>
          <hr className="my-5" />

          <div className="text-center">
            <Row className="mb-5">
              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">Definition</Card.Title>
                    <Card.Text className="text-muted">{definition}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faBullseye}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">Key Characteristics</Card.Title>
                    <Card.Text className="text-muted">
                      <ul>
                        {characteristics.map((char, index) => (
                          <li key={index}>{char}</li>
                        ))}
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faListAlt}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">
                      Examples & Related Terms
                    </Card.Title>
                    <Card.Text className="text-muted">
                      <strong>Examples:</strong>
                      <ul>
                        {examples.map((example, index) => (
                          <li key={index}>{example}</li>
                        ))}
                      </ul>
                      <strong>Related Terms:</strong>
                      <ul>
                        {relatedTerms.map((term, index) => (
                          <li key={index}>{term}</li>
                        ))}
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BullMarketGlossary;
