import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faInfoCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const DiversificationSimulator = () => {
  const [result, setResult] = useState(null);
  const [stocks, setStocks] = useState("");
  const [commodities, setCommodities] = useState("");
  const [realEstate, setRealEstate] = useState("");
  const [cash, setCash] = useState("");

  const handleCalculate = (e) => {
    e.preventDefault();
    const s = parseFloat(stocks) || 0;
    const com = parseFloat(commodities) || 0;
    const r = parseFloat(realEstate) || 0;
    const c = parseFloat(cash) || 0;

    const total = s + com + r + c;

    const percentages = {
      stocks: ((s / total) * 100).toFixed(2),
      commodities: ((com / total) * 100).toFixed(2),
      realEstate: ((r / total) * 100).toFixed(2),
      cash: ((c / total) * 100).toFixed(2),
    };

    setResult(percentages);
  };

  return (
    <Container>
      <Row>
        <Col md={12} className="text-md-start">
          <Button
            variant="outline-primary"
            as={Link}
            to="/resources"
            className="mb-3"
          >
            Back to Resources
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <div className="text-center">
            <h1 className="mb-4 display-4">Diversification Simulator</h1>
            <p className="lead">
              Understand the impact of diversifying your investments
            </p>
          </div>
          <hr className="my-5" />

          <div className="text-center">
            <Row className="mb-5">
              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">
                      Introduction to Diversification
                    </Card.Title>
                    <Card.Text className="text-muted">
                      Diversification is a strategy that mixes a wide variety of
                      investments within a portfolio. This approach aims to
                      minimise risk by investing in different assets.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faChartPie}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">
                      Simulate Your Portfolio
                    </Card.Title>
                    <Form onSubmit={handleCalculate}>
                      <Form.Group controlId="stocks" className="mb-3">
                        <Form.Label>Stocks (£)</Form.Label>
                        <Form.Control
                          type="number"
                          value={stocks}
                          onChange={(e) => setStocks(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="commodities" className="mb-3">
                        <Form.Label>Commodities (£)</Form.Label>
                        <Form.Control
                          type="number"
                          value={commodities}
                          onChange={(e) => setCommodities(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="realEstate" className="mb-3">
                        <Form.Label>Real Estate (£)</Form.Label>
                        <Form.Control
                          type="number"
                          value={realEstate}
                          onChange={(e) => setRealEstate(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="cash" className="mb-3">
                        <Form.Label>Cash (£)</Form.Label>
                        <Form.Control
                          type="number"
                          value={cash}
                          onChange={(e) => setCash(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Calculate
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">Calculation Result</Card.Title>
                    <Card.Text className="text-muted">
                      {result ? (
                        <>
                          Stocks: {result.stocks}% <br />
                          Commodities: {result.commodities}% <br />
                          Real Estate: {result.realEstate}% <br />
                          Cash: {result.cash}%
                        </>
                      ) : (
                        "Please fill in the form to see the result."
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DiversificationSimulator;
