import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Modal,
  Form,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const quizQuestions = [
  {
    question: "What is a stock?",
    options: [
      "A type of bond",
      "Ownership in a company",
      "A loan to a company",
    ],
    correct: "Ownership in a company",
  },
  {
    question: "What are common stocks?",
    options: [
      "Stocks that offer guaranteed dividends",
      "Stocks that give voting rights but no guaranteed dividends",
      "Stocks that do not offer voting rights",
    ],
    correct: "Stocks that give voting rights but no guaranteed dividends",
  },
  {
    question: "Where can you buy stocks?",
    options: ["At a local store", "On stock exchanges", "From the government"],
    correct: "On stock exchanges",
  },
  {
    question: "What are dividends?",
    options: [
      "Payments made to shareholders from a company's profits",
      "Payments made to bondholders",
      "Payments made to banks for loans",
    ],
    correct: "Payments made to shareholders from a company's profits",
  },
  {
    question: "What is market capitalization?",
    options: [
      "The total number of shares a company has issued",
      "The total value of a company's outstanding shares",
      "The earnings per share of a company",
    ],
    correct: "The total value of a company's outstanding shares",
  },
];

const Stocks101 = () => {
  const [showModal, setShowModal] = useState(false);
  const [quizAnswers, setQuizAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [canProceed, setCanProceed] = useState(false);

  const handleQuizClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentQuestion(0);
    setQuizAnswers({});
    setScore(0);
  };

  const handleAnswerChange = (question, answer) => {
    setQuizAnswers({
      ...quizAnswers,
      [question]: answer,
    });
    setCanProceed(true); // Enable proceeding to next question once an answer is selected
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    setCanProceed(false); // Disable proceeding until the next answer is selected
  };

  const handleSubmitQuiz = (e) => {
    let newScore = 0;
    quizQuestions.forEach((q) => {
      if (quizAnswers[q.question] === q.correct) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowModal(true); // Keep modal open to show results
  };

  const renderQuizQuestion = (question, index) => (
    <Card key={index} className="mb-4">
      <Card.Body>
        <Card.Title>Question {index + 1}</Card.Title>
        <Card.Text>{question.question}</Card.Text>
        <Form.Group>
          {question.options.map((option, idx) => (
            <Form.Check
              key={idx}
              type="radio"
              name={`question-${index}`}
              label={option}
              value={option}
              checked={quizAnswers[question.question] === option}
              onChange={() => handleAnswerChange(question.question, option)}
              required
            />
          ))}
        </Form.Group>
      </Card.Body>
    </Card>
  );

  return (
    <Container>
      <Row>
        <Col md={12} className="text-md-start">
          <Button
            variant="outline-primary"
            as={Link}
            to="/resources"
            className="mb-3"
          >
            Back to Resources
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <div className="text-center">
            <h1 className="mb-4 display-4">Stocks 101</h1>
            <p className="lead">
              Understand the fundamentals of stock investing
            </p>
          </div>
          <hr className="my-5" />

          <Row className="justify-content-center mb-5">
            <Col md={12}>
              <Accordion className="mb-4" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Introduction to Stocks</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Stocks represent ownership in a company and entitle the
                      shareholder to a portion of the company's profits and
                      assets. This section covers the basics of what stocks are
                      and how they work.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Types of Stocks</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      There are several types of stocks, including common stocks
                      and preferred stocks. Common stocks give shareholders
                      voting rights but no guaranteed dividends. Preferred
                      stocks provide dividends but typically do not offer voting
                      rights.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    How to Buy and Sell Stocks
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Stocks are bought and sold on stock exchanges, such as the
                      New York Stock Exchange (NYSE) and the NASDAQ. Investors
                      can buy stocks through a brokerage account or an online
                      trading platform.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Dividends and Market Capitalization
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Dividends are payments made to shareholders from a
                      company's profits. Market capitalization refers to the
                      total value of a company's outstanding shares.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Button variant="primary" onClick={handleQuizClick}>
                Take Quiz
              </Button>
            </Col>
          </Row>

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Stocks 101 Quiz</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentQuestion < quizQuestions.length ? (
                renderQuizQuestion(
                  quizQuestions[currentQuestion],
                  currentQuestion
                )
              ) : (
                <Form onSubmit={handleSubmitQuiz}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Quiz Results</Card.Title>
                      <Card.Text>
                        You scored {score} out of {quizQuestions.length}.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              {currentQuestion < quizQuestions.length - 1 && (
                <Button
                  variant="secondary"
                  onClick={handleNextQuestion}
                  disabled={!canProceed}
                >
                  Next
                </Button>
              )}
              {currentQuestion === quizQuestions.length - 1 && (
                <Button
                  variant="primary"
                  onClick={() => {
                    handleNextQuestion();
                    handleSubmitQuiz();
                  }}
                  disabled={!canProceed}
                >
                  Submit Quiz
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default Stocks101;
