import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faNewspaper,
  faBrain,
} from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10}>
          <div className="text-center">
            <h1 className="mb-4 display-4">Welcome to StockSnap</h1>
            <p className="lead">
              Your gateway to informed stock investing decisions.
            </p>
          </div>
          <hr className="my-5" />
          <div className="text-center">
            <h2 className="mb-4">Why StockSnap?</h2>
            <p>
              StockSnap empowers investors with real-time data, insightful news,
              and predictive analytics to make well-informed investment
              decisions. Whether you're new to investing or a seasoned trader,
              StockSnap provides the tools you need to succeed.
            </p>
          </div>
          <hr className="my-5" />
          <Row className="mb-5 text-center">
            <Col md={4} className="mb-3">
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body>
                  <FontAwesomeIcon
                    icon={faChartLine}
                    size="3x"
                    className="mb-3 text-primary"
                  />
                  <Card.Title className="h5">Real-time Stock Prices</Card.Title>
                  <Card.Text className="text-muted">
                    Access up-to-date stock prices for informed decision-making.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-3">
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body>
                  <FontAwesomeIcon
                    icon={faNewspaper}
                    size="3x"
                    className="mb-3 text-primary"
                  />
                  <Card.Title className="h5">Latest Market News</Card.Title>
                  <Card.Text className="text-muted">
                    Stay updated with the latest news affecting your
                    investments.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-3">
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body>
                  <FontAwesomeIcon
                    icon={faBrain}
                    size="3x"
                    className="mb-3 text-primary"
                  />
                  <Card.Title className="h5">Predictive Analytics</Card.Title>
                  <Card.Text className="text-muted">
                    Leverage machine learning predictions to anticipate market
                    trends.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
