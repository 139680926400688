import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faInfoCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const CompoundInterestCalculator = () => {
  const [result, setResult] = useState(null);

  const [principal, setPrincipal] = useState("");
  const [rate, setRate] = useState("");
  const [time, setTime] = useState("");
  const [compounds, setCompounds] = useState("");

  const handleCalculate = (e) => {
    e.preventDefault();
    const P = parseFloat(principal);
    const r = parseFloat(rate) / 100;
    const t = parseFloat(time);
    const n = parseFloat(compounds);

    const amount = P * Math.pow(1 + r / n, n * t);
    const interest = amount - P;
    setResult({ amount: amount.toFixed(2), interest: interest.toFixed(2) });
  };

  return (
    <Container>
      <Row>
        <Col md={12} className="text-md-start">
          <Button
            variant="outline-primary"
            as={Link}
            to="/resources"
            className="mb-3"
          >
            Back to Resources
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <div className="text-center">
            <h1 className="mb-4 display-4">Compound Interest Calculator</h1>
            <p className="lead">
              Calculate the future value of your investments
            </p>
          </div>
          <hr className="my-5" />

          <div className="text-center">
            <Row className="mb-5">
              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">
                      Introduction to Compound Interest
                    </Card.Title>
                    <Card.Text className="text-muted">
                      Compound interest is the addition of interest to the
                      principal sum of a loan or deposit. It is the result of
                      reinvesting interest, rather than paying it out, so that
                      interest in the next period is then earned on the
                      principal sum plus previously accumulated interest.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faCalculator}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">
                      Calculate Compound Interest
                    </Card.Title>
                    <Form onSubmit={handleCalculate}>
                      <Form.Group controlId="principal" className="mb-3">
                        <Form.Label>Principal Amount (£)</Form.Label>
                        <Form.Control
                          type="number"
                          value={principal}
                          onChange={(e) => setPrincipal(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="rate" className="mb-3">
                        <Form.Label>Annual Interest Rate (%)</Form.Label>
                        <Form.Control
                          type="number"
                          step="0.01"
                          value={rate}
                          onChange={(e) => setRate(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="time" className="mb-3">
                        <Form.Label>Time (years)</Form.Label>
                        <Form.Control
                          type="number"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="compounds" className="mb-3">
                        <Form.Label>Compounds Per Year</Form.Label>
                        <Form.Control
                          type="number"
                          value={compounds}
                          onChange={(e) => setCompounds(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Calculate
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-3">
                <Card className="h-100 border-0 shadow-sm">
                  <Card.Body>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size="3x"
                      className="mb-3 text-primary"
                    />
                    <Card.Title className="h5">Calculation Result</Card.Title>
                    <Card.Text className="text-muted">
                      {result ? (
                        <>
                          Future Value: £{result.amount} <br />
                          Interest Earned: £{result.interest}
                        </>
                      ) : (
                        "Please fill in the form to see the result."
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CompoundInterestCalculator;
